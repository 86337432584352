.container {
    width: 30vw;
    height: auto;
    min-height: 25vh;
    background-color: #ddd;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

#tipo-unidade {
    margin-top: 1vw;
    box-sizing: border-box;
    border-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 100%;   
    text-align: center;
    font-size: 20px;

}

.inputs {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
    box-sizing: border-box;

}

.convertido , .converter {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #aaa;
    background-color: #ddd;
}

.inputs select {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

#unidade-convertida , #unidade-a-converter {
    background-color: #aaa;
}

.inputs input , .inputs select {
    display: flex;
    justify-content: space-around;
    height: 32px;
    background-color: transparent;
    border-style: none;
    text-align: center;
    width: 100%;
}
