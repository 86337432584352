body {
  margin: 0;
  background-color: #ccc;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
}

.container-main {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-around;
  align-content: center;
}
